<template>
  <carousel
    :autoplay="true"
    :autoplayTimeout="8000"
    :items="1"
    responsiveBaseElement="body"
    @changed="updateActiveIndex"
  >
    <lottie-vue-player
      v-for="(item, i) in items"
      :key="'animation-' + i"
      :src="item"
      id="player"
      :theme="options.theme"
      :player-size="options.playerSize"
      :player-controls="false"
      :loop="false"
      style="width: 100%"
      :ref="'lottie' + i"
    ></lottie-vue-player>
  </carousel>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  components: { carousel },
  data() {
    return {
      index: 0,
      items: [
        '/animations/rise1.json',
        '/animations/rise2.json',
        '/animations/rise3.json',
        '/animations/rise4.json',
      ],
      visitedSlide: [false, false, false, false],
      options: {
        minimizable: false,
        playerSize: 'standard',
        backgroundColor: '#fff',
        backgroundStyle: 'color',
        theme: {
          controlsView: 'standard',
          active: 'light',
          light: {
            color: '#3D4852',
            backgroundColor: '#fff',
            opacity: '0.7',
          },
          dark: {
            color: '#fff',
            backgroundColor: '#202020',
            opacity: '0.7',
          },
        },
      },
    }
  },

  methods: {
    updateActiveIndex(event) {
      const ref = 'lottie' + event.item.index
      this.index = null
      this.index = event.item.index
      let playerRef = this.$refs[ref][0]
      if (playerRef) {
        // loop >= #2, pause the animation on the last frame
        if (this.visitedSlide[this.index]) {
          // pause animation on last frame (need to scour from the original source code)
          playerRef.$refs['player'].complete()
        } else {
          // stop animation to make it start from the beginning
          playerRef.stop()
          setTimeout(() => {
            // play the animation
            playerRef.togglePlayPause()
            // make visitedSlide returns true to stop the animation
            this.visitedSlide[this.index] = true
          }, 500)
        }
      }
    },
  },

  mounted() {
    const event = {
      item: {
        index: 0,
      },
    }
    this.updateActiveIndex(event)
  },
}
</script>
